.footer {
    display: flex;
    margin-top: 0px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #242424;
}

.rights {
    margin-left: 5px;
    color: beige;
    font-size: small;
}
