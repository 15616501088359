a {
    text-decoration: none;
}

.Main {
    background-color: rgb(92, 240, 240);
    text-align: center;
    padding-bottom: 10px;
    flex: 1 1 90%;
}

.WholeScreen {
    display: flex;
    flex-direction: column;
    height: 100%;
}
