.Item {
    width: 90%;
    margin: auto;
    border: 1px solid #f2f2f2;
    background: #fff;
    margin-top: 5px;
    border-radius: 10px;
    margin-bottom: 3px;
    box-shadow: 5px 3px 3px #a72626;
    transform: translate(-600%, 0);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.ItemEnter {
    opacity: 0.5;
    transform:translate(-50% , 0);
    transition: all 500ms ease-in;
}

.ItemEnterActive {
    opacity: 1;
    transform:translate(0 , 0);
    transition: all 500ms ease-in;
}

.ItemEnterDone {
    opacity: 1;
    transform:translate(0 , 0);
    transition: all 500ms ease-in;
}

.ItemExit {
    opacity: 1;
    transform:translate(0 , 0);
    transition: all 500ms ease-in;
}

.ItemExitActive {
    opacity: 0.8;
    transform:translate(50% , 0);
    transition: all 500ms ease-in;
}

.ItemExitDone {
    opacity: 0;
    transform:translate(350% , 0);
    transition: all 500ms ease-in;
}

.Left {
    position:relative;
    display: inline-block;
    width: 75px;
    height: 75px;
    margin-left: 10px;
}

.Left img {
    max-width: 75px;
    max-height: 75px;
    min-width: 75px;
    min-height: 75px;
    display: block;
}

.Right {
    display: inline-block;
}

.Play {
    position: absolute;
    top: 30%;
    left: 30%;
    height: 40%;
    width: 40%;
    opacity: 1;
}

.Play img {
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
}

.WrapperRight {
    flex-grow: 1;
    margin-left: 25px;
}
