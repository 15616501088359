.Slider {
    width: 80%;
    margin: 0 auto;

}

.Item {
    position: relative;
}

.Image {
    height: 150px;
    background-size: cover!important;
    background-repeat: no-repeat!important;
}

.Item a {
    position: absolute;
    width: 80%;
    bottom: 20px;
    text-decoration: none;
    right: 10%;
    left: 10%;
    background-color: lightcyan;
    color: black;
}

.Title {
    top: 0px;
    width: 100%;
    right: 0px;
    font-weight: 500;
    font-size:small;
    box-sizing: border-box;
}

@media (min-width: 350px) {
    .Image {
        height: 175px;
    }
}

@media (min-width: 400px) {
    .Image {
        height: 190px;
    }
}

@media (min-width: 450px) {
    .Image {
        height: 220px;
    }
}

@media (min-width: 500px) {
    .Image {
        height: 250px;
    }
    .Title {
	font-size:medium;
   }	
}

@media (min-width: 550px) {
    .Image {
        height: 275px;
    }
}

@media (min-width: 650px) {
    .Image {
        height: 300px;
    }
}

@media (min-width: 725px) {
    .Image {
        height: 325px;
    }
    .Title {
	font-size:large;
   }	
}

@media (min-width: 785px) {
    .Image {
        height: 350px;
    }
}

@media (min-width: 800px) {
    .Image {
        height: 375px;
    }
}

@media (min-width: 850px) {
    .Image {
        height: 400px;
    }
    .Title {
	font-size:x-large;
   }	
}

@media (min-width: 900px) {
    .Image {
        height: 425px;
    }
}

@media (min-width: 1050px) {
    .Image {
        height: 450px;
    }
}

@media (min-width: 1125px) {
    .Image {
        height: 475px;
    }
}

@media (min-width: 1200px) {
    .Image {
        height: 500px;
    }
    .Title {
	font-size:xx-large;
   }	
}

@media (min-width: 1275px) {
    .Image {
        height: 525px;
    }
}

@media (min-width: 1350px) {
    .Image {
        height: 550px;
    }
}

@media (min-width: 1400px) {
    .Image {
        height: 575px;
    }
}

@media (min-width: 1450px) {
    .Image {
        height: 600px;
    }
}

@media (min-width: 1500px) {
    .Image {
        height: 625px;
    }
}
