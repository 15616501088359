.NewsList {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
}

.NewsList h2 {
    font-size: small;
    line-height: 21px;
    margin: 5px 0;
    color: #525252;
}

.NewsList a {
    text-decoration: none;
}

.LoadMore {
    margin-top: 15px;
    margin-bottom: 15px;
}

@media (min-width: 500px) {
    .NewsList h2 {
        font-size:medium;
   }
}
