.header {
    background: #242424;
    border-bottom: 1px solid #000;
    color: #ffff;
    height: 50px;
    margin-bottom: 2px;
}

.headerOpt {
    display: flex;
    height: 100%;
    padding-left: 10px;
}
