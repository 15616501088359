html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #ececec;
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

div#root {
  height: 100%;
}
