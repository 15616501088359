.VideosList {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
}

.VideosList h2 {
    font-size: small;
    line-height: 21px;
    margin: 5px 0;
    color: #525252;
}

.VideosList h3 {
    margin: 0px;
}

.VideosList a {
    text-decoration: none;
}

.LoadMore {
    margin-top: 15px;
    margin-bottom: 15px;
}

@media (min-width: 500px) {
    .VideosList h2 {
        font-size:medium;
   }
}
